import React from 'react';
import Slider from 'react-slick'; // Import react-slick
import 'slick-carousel/slick/slick.css'; // Import slick-carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import additional theme styles
import './FullWidthSlider.css'; // Import your custom CSS for animations

import BusinessStatus from './BusinessStatus'; 
// Sample data for the slider
const sliderItems = [
  {
    id: 1,
    title: 'Porsche',
    description: 'Germany car',
    image: 'https://res.cloudinary.com/dzjykqcep/image/upload/v1722939859/additional/ysb2wdyagmotuv7mwfqd.jpg',
  },
  {
    id: 2,
    title: 'Germany',
    description: 'Germany car',
    image: 'https://res.cloudinary.com/dzjykqcep/image/upload/v1722939856/additional/rddhmf92mfmjckjq44sa.jpg',
  },
  {
    id: 3,
    title: 'Loader',
    description: 'Germany car',
    image: 'https://res.cloudinary.com/dzjykqcep/image/upload/v1722939853/additional/lulrnog5dqscxrap7qmd.jpg',
  },
];

// Slider settings for animations and other options
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  fade: true,
  pauseOnHover: false,
};

const FullWidthSlider = () => (
  <div style={{ width: '100%' }} className='sliderOnMobile'>
    <Slider {...sliderSettings}>
      {sliderItems.map((item) => (
        <div key={item.id} className="slide-animation"> {/* Apply the custom animation */}
          <img
            src={item.image}
            alt={item.title}
            style={{ width: '100%', height: 'auto' }}
          />
         
        </div>
      ))}
    </Slider>
    <BusinessStatus className="openCss"/> {/* Display the business status */}
  </div>
);

export default FullWidthSlider;

// src/components/Header.js
import React , { useState }from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './languageSwitcher'; // Correct import case
import './headerCss.css';
import PopupMenu from './MenuOpened';

import PhoneButton from './PhoneButton';

//Iconite
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuIcon from '@mui/icons-material/Menu';
import WhatsAppButton from './WhatsuppButton';

import configDetails from './../components/utils/ConfigDetails';


const Header = () => {
  
  const { t } = useTranslation(); // Use the `t` function to get translations
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleEmailClick = () => {
    window.location.href = `mailto:${configDetails.email}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${configDetails.sw}`; // Phone number with country code
  };
  const redirectHome = () => {
    window.location.href = `/`; // Phone number with country code
  };

  const handleWhatsAppClick = () => {
    const phone = configDetails.wappsw; // WhatsApp phone number with country code
    const message = 'Hello, I would like more information...'; // Custom message
    const url = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank'); // Open WhatsApp in a new tab
  };
  
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  return (
   
    <AppBar position="sticky" className='headerBarParent' elevation={0}> {/* Transparent and sticky */}
      <Toolbar className='logoCenteredMobile'>
        <img src='../iconite_categorii_2/logobfmb.png' alt='logo' onClick={redirectHome}  style={{ marginRight: '10px', width: '15%', cursor:'pointer'}}  className='logoCenteredMobile' id='forlogomobile'/> {/* Space after the logo */}
        <div className='menuDesktop' style={{ flexGrow: 1 }}>
        <Button color="inherit" component={Link} to="/" className="header-button">
          {t('HomePage')} {/* Home */}
        </Button>
        
        <Button color="inherit" component={Link} to="/company" className="header-button">
          {t('Company')} {/* Contact */}
        </Button>

        <Button color="inherit" component={Link} to="/services" className="header-button">
          {t('Services')} {/* Contact */}
        </Button>

        <Button color="inherit" component={Link} to="/transport" className="header-button">
          {t('Transport')} {/* Contact */}
        </Button>

        <Button color="inherit" component={Link} to="/export" className="header-button">
          {t('Export')} {/* Contact */}
        </Button>

        <Button color="inherit" component={Link} to="/find-us" className="header-button">
          {t('WhereToFindUs')} {/* Contact */}
        </Button>

        <Button color="inherit" component={Link} to="/contact" className="header-button">
          {t('ContactPage')} {/* Contact */}
        </Button>
       
        <Button  className="header-button backgroundgreenandrightFloating"  component={Link} to="/inventory" style={{background:'green!important'}}>
          {t('machineryList')}
        </Button>
        </div>
        <div className="right-aligned displayNone">
        
        
        <LanguageSwitcher className="displayNone"/> {/* Language switcher */}
        <EmailIcon style={{color:'#bf9900', fontSize: '26px'}} className='buttonFormOutline marginLeft noEmailMobile displayNone'     onClick={handleEmailClick}/>
        <PhoneIcon style={{color:'#bf9900', fontSize: '26px'}} className='buttonFormOutline marginLeft displayNone'     onClick={handlePhoneClick}/>
        <WhatsAppIcon style={{color:'#bf9900', fontSize: '26px'}} className='buttonFormOutline marginLeft displayNone'  onClick={handleWhatsAppClick}/>
        
        </div>

        
      </Toolbar >
      <div className='displayNoneDesktop marginLeft20A'>
     
      <LanguageSwitcher className="marginLeft20A displayNoneDesktop mobile-inline marginLeft20" />
      <EmailIcon style={{color:'#bf9900', fontSize: '26px'}} className='buttonFormOutline marginLeft     '     onClick={handleEmailClick}/>
      <MenuIcon className="displayNoneDesktop MenuIconNoDesktop mobile-inline marginRight20 fontSiz40" onClick={handlePopupOpen} />
      <PopupMenu open={isPopupOpen} onClose={handlePopupClose} />
    </div>
    <div className='displayNoneDesktop marginLeft20A'>
   
    
    <div className="phoneButtonContainer"> {/* CSS class for mobile-specific styling */}
      <PhoneButton style={{marginRight: '35px'}} label= {t('CallUs')}   onClick={handlePhoneClick} />
      <WhatsAppButton label="Whatsapp" onClick={handleWhatsAppClick} className='marginLeft' />
    </div>


      
     
   </div>
      
    </AppBar>
  );
};

export default Header;

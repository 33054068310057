// src/components/BusinessStatus.js
import React from 'react';
import { isBusinessOpen } from './utils/businessLogic'; // Function to check business status
import './style/BusinessStatus.css'; // Import custom styles if needed
import { useTranslation } from 'react-i18next';

const BusinessStatus = () => {
  const { t } = useTranslation();
  const { isOpen, nextOpenDay, nextOpenTime, closingTime } = isBusinessOpen(); // Get business status

  console.log("isOpen:", isOpen); // Debugging
  console.log("nextOpenDay:", nextOpenDay); // Debugging
  console.log("nextOpenTime:", nextOpenTime); // Debugging
  console.log("closingTime:", closingTime); // Debugging

  return (
    <div className={`business-status ${isOpen ? 'openCss' : 'openCss'}`}>
      {isOpen ? (
        <span>{t('OpenNow')} {closingTime}</span> // If business is open
      ) : (
        <span>
          {t('ClosedNow')} 
          {nextOpenDay ? ` ${t(nextOpenDay)}` : ''} 
          {nextOpenTime ? ` ${t('ClosedNow1')} ${nextOpenTime}` : ''}
        </span> // If business is closed
      )}
    </div>
  );
};

export default BusinessStatus;

import React from 'react';
import './SearchColumn.css';
import './ContactForm.css';
// Define the component to fetch and display data
import { useTranslation } from 'react-i18next';
const BuyMachinery = () => {
  const { t } = useTranslation();


  return (
     <div>
    <div className='searchDiv'>
  
  <div className="vertical-stack"> {/* Parent container for vertical layout */}
     

      <p align="center">
        <img src='/deal.png' alt='We buy your vehicle '></img>
        
       </p>
     
    <p align="center">
    {t('WeBuyYourVehicleText')}
    </p>
      
    </div>
  </div>

  

  </div>
  );
};

export default BuyMachinery;

// src/App.js
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/Home';
import Inventory from './pages/Inventory';
import MachineryDetails from './pages/MachineryDetails';
import Company from './pages/Company';
import Services from './pages/Services';
import Transport from './pages/Transport';
import Export from './pages/Export';
import FindUs from './pages/WhereToFindUs';
import Contact from './pages/Contact';
import ViewProductsBasedOnCategory from './pages/ViewProductsBasedOnCategory';
import ViewProductsBasedOnMake from './pages/ViewProductsBasedOnMake';
import SearchComponent from './pages/SearchProducts';
import Reserve from './pages/Reserve';
import BuyNow from './pages/Buy-now.js';
import './components/headerCss.css';
import './components/mobile.css';
import CookiePopup from './components/Cookie';
const App = () => (
  <I18nextProvider i18n={i18next}>
    <Router>
    <CookiePopup />
      <Header />
      <Routes>
      
        <Route path="/" element={<Home />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/details/:id" element={<MachineryDetails />} />
        <Route path="/company" element={<Company />} />
        <Route path="/services" element={<Services />}/>
        <Route path="/transport" element={<Transport />}/>
        <Route path="/export" element={<Export />}/>
        <Route path="/find-us" element={<FindUs />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/category/:category" element={<ViewProductsBasedOnCategory />} />
        <Route path="/search/:category/:make" element={<SearchComponent />} />
        <Route path="/details/:reference" element={<MachineryDetails />} />
        <Route path="/make/:make" element={<ViewProductsBasedOnMake />} />
        <Route path="/reserve/:stocknumber" element={<Reserve />} />
        <Route path="/buy-now/:stocknumber" element={<BuyNow />} />
        
      </Routes>
      <Footer />
    </Router>
  </I18nextProvider>
);

export default App;

import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import HomePageLeft from '../components/HomePageLeft';

import SearchFilter from './SearchFilter'; // Adjust the import path accordingly

import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import { useTranslation } from 'react-i18next';

const SearchComponent = () => {
  const { category,make } = useParams(); // Extract category from URL parameters
  
  const { t } = useTranslation();

  return (
    <Container maxWidth={false} style={{ marginTop: '70px', paddingLeft: '0px' }}>
      <div className="two-column-layout paddingTop10">
        <div className="column-left">
          <HomePageLeft />
        </div>
        <div className="column-right">
          <div className="vehicle-details">
            <div className="vehicle-specifications">{t('Category')}: {category} | Make: {make} </div>
            <div>
              <SearchFilter /> {/* Display products based on the category */}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SearchComponent;

import React from 'react';

import Container from '@mui/material/Container';

import HomePageLeft from '../components/HomePageLeft';

import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import { useTranslation, Trans} from 'react-i18next';


const Transport = () => {

  const { t } = useTranslation();

  return (
    <Container maxWidth={false} style={{ marginTop: '70px', paddingLeft: '0px' }}>
    
        <div className="two-column-layout paddingTop10">
          <div className="column-left">
            <HomePageLeft />
          </div>
          <div className="column-right">
          <div className="vehicle-details">
          <div className="vehicle-specifications">{t('Transport')}</div>
            <div>
            <img className="nodisplayonmobile" src='./transport.jpg' width={'100%'} height={'50%'} alt='Export'> 
            </img>
                <p style={{padding:'10px'}}>
                <Trans i18nKey="TransportPageText" components={{ br: <br /> }} />
          
                </p>
            </div>

           
          </div>
          </div>
        </div>
     
    </Container>
  );
};

export default Transport;

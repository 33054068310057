import React from 'react';

import Container from '@mui/material/Container';

import HomePageLeft from '../components/HomePageLeft';

import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import { useTranslation} from 'react-i18next';

import Products from './../components/AllProducts';

const Services = () => {

  const { t } = useTranslation();

  return (
    <Container maxWidth={false} style={{ marginTop: '70px', paddingLeft: '0px' }}>
    
        <div className="two-column-layout paddingTop10">
          <div className="column-left">
            <HomePageLeft />
          </div>
          <div className="column-right">
          <div className="vehicle-details">
          <div className="vehicle-specifications">{t('machineryList')}</div>
            <div>
                <Products />
              
            </div>
          </div>
          </div>
        </div>
     
    </Container>
  );
};

export default Services;

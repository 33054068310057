import React from 'react';
import CategoryCards from '../components/CategoryCards';
const HomePageRight = () => {


  // Render the fetched data
  return (
    <div className="column column-right" style={{marginRight:'20px', width:'100%!important'}}>
    <div
          className="titleOverP"
          style={{
            background: 'gold', 
            marginBottom: '10px', 
            fontWeight: 'bold',
            padding: '5px', 
            paddingLeft: '15px', 
            textAlign: 'left', 
            textTransform: 'uppercase',
          }}>
          Our Inventory
      </div>
    <CategoryCards/>
      </div> 
  );
};

export default HomePageRight;








import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookiePopup.css'; // Ensure this file is created with the CSS above

const CookiePopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setShowPopup(false);
  };
  const handleRefuse = () => {
    Cookies.set('cookieConsent', 'false', { expires: 365 });
    setShowPopup(false);
  };

  return (
    showPopup && (
      <div className="cookie-popup">
        <div className="cookie-popup-content">
            <h3 style={{fontWeight:'600'}}>
            We use cookies!
            </h3>

          <p style={{fontSize:'.9em', lineHeight: ' 1.5em' , fontWeight:'300'}}>This website uses cookies of its own to make usage statistics. If you want more information regarding <span style={{textDecoration:'underline'}}>Cookie Policy</span> visit our <span style={{textDecoration:'underline'}}>Contact Page!</span></p>
          <div className='parentdivbuttons' style={{display:'inline-flex', justifyContent:'center', alignItems:'center', width:'100%'}}>

          <button style={{marginRight:'10px'}} onClick={handleAccept}>Accept all</button>
          <button onClick={handleRefuse}>Reject all</button><div></div>
          </div>
          
        </div>
      </div>
    )
  );
};

export default CookiePopup;

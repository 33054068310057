import React from 'react';

import Container from '@mui/material/Container';

import HomePageLeft from '../components/HomePageLeft';

import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import { useTranslation} from 'react-i18next';
import configDetails from './../components/utils/ConfigDetails';

const Services = () => {

  const { t } = useTranslation();

  return (
    <Container maxWidth={false} style={{ marginTop: '70px', paddingLeft: '0px' }}>
    
        <div className="two-column-layout paddingTop10">
          <div className="column-left">
            <HomePageLeft />
          </div>
          <div className="column-right">
          <div className="vehicle-details">
          <div className="vehicle-specifications">{t('WhereToFindUs')}</div>
            <div>
                
              



                <div className='inlineDisplay' >
                <div className="sub-column-left">
            
                
                <div style={{alignItems:'center', justifyContent:'center'}}><h1>Main office</h1>
                <p style={{marginBottom:'0'}}>
                Dialoggatan 5<br></br>
                703 74 Örebro<br></br>
                Sweden<br></br><br></br>

                Telephone: {configDetails.swFormatted}<br></br>
                
                Email: {configDetails.email}<br></br><br></br>


              </p>
              <div style={{display:'none'}}>WhatsApp: {configDetails.wappswFormatted}<br></br></div>


                {/* <iframe src="https://www.bing.com/maps?osid=af67bca8-81c4-40fd-a6ef-5a935564585e&cp=59.300112~15.197115&lvl=19&style=g&pi=0&imgid=0c1434e8-ecd7-4fbf-81d5-116d167e3d75&v=2&sV=2&form=S00027" width="100%" height="450" style={{border:'0'}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="directions to us"></iframe>
                 */}
                
                </div>
                    </div>


                    {/* dezactivat spain location for the moment */}
                    <div className="sub-column-right" style={{flex:'2 1' , display:''}}>   
                   
                
                
                    <div style={{alignItems:'center', justifyContent:'center'}}><h1>International transaction office</h1>
                    <p style={{marginBottom:'0'}}>


                    Pol. Ind. Santa Rita, Carrer Acústica 14<br></br> 
                    08755 Castellbisbal, Barcelona<br></br>
                    Spain<br></br><br></br>

                {/* Telephone: {configDetails.spain}<br></br> */}
                
                Email: {configDetails.emailSpanish}<br></br><br></br>
                

              </p>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d773.4550076868989!2d1.9972226631020213!3d41.45930721029354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49182775da9e3%3A0x9613899cb1e59397!2sBFMB%20GLOBAL!5e1!3m2!1sen!2sro!4v1721504211867!5m2!1sen!2sro" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="directions to us"></iframe> */}
                
                </div>
                    </div>
                    </div>

                
                
            </div>
          </div>
          </div>
        </div>
     
    </Container>
  );
};

export default Services;

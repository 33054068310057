// configDetails.js
const configDetails = {
    swFormatted: "+46 855 922 233",
    sw: '+46855922233',
    wappsw: "+46736583179",
    wappswFormatted: "+46 73 658 31 79",
    email: "sales@bfmbglobal.com",
    spain: "+34 611 290 992",
    infoEmail: 'info@bfmbglobal.com',
    emailSpanish:'ventas@bfmbglobal.com',
};

export default configDetails;



